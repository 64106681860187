const transcript = {
  familyStatus: (key: string) =>
    ({
      married: "MARRIED",
      MARRIED: "married",
      single: "SINGLE",
      SINGLE: "single",
      nan: "nan",
      WIDOWED: "widowed",
      widowed: "WIDOWED",
      DIVORCED: "divorced",
      divorced: "DIVORCED",
      SEPERATED: "seperated",
      seperated: "SEPERATED",
      OTHER: "other",
      other: "OTHER",
    }[key] ?? ""),
  gender: (key: string) =>
    ({
      Female: "female",
      female: "Female",
      Male: "male",
      male: "Male",
      nan: "nan",
    }[key] ?? ""),
  employment_spheres: (key: string) =>
    ({
      Retired: "retired",
      retired: "Retired",
      "Public sect emp": "pub-sect-emp",
      "pub-sect-emp": "Public sect emp",
      nan: "nan",
      "Private sect emp": "priv-sect-emp",
      "priv-sect-emp": "Private sect emp",
      Unemployed: "unemployed",
      unemployed: "Unemployed",
      "Business owner": "business-owner",
      "business-owner": "Business owner",
      "Jab employee": "jab-employee",
      "jab-employee": "Jab employee",
      Interorg: "int-orgs",
      "int-orgs": "Interorg",
      "Self employed": "self-employed",
      "self-employed": "Self employed",
      "Private sect empprivate sect emp": "private-sect-empprivate-sect-emp",
      "private-sect-empprivate-sect-emp": "Private sect empprivate sect emp",
      Resigned: "resigned",
      resigned: "Resigned",
      "Private sect empbanker": "private-sect-empbanker",
      "private-sect-empbanker": "Private sect empbanker",
      "Prof self employed": "prof-self-employed",
      "prof-self-employed": "Prof self employed",
      Banker: "banker",
      banker: "Banker",
      lawyer: "Lawyer",
      Lawyer: "lawyer",
      unions: "Unions",
      Unions: "unions",
      other: "Other",
      Other: "other",
      None: "none",
      none: "none",
    }[key] ?? ""),
  collateral: (key: string) =>
    ({
      no: "No",
      No: "no",
      yes: "Yes",
      Yes: "yes",
    }[key] ?? ""),
  coBorrower: (key: string) =>
    ({
      no: "No",
      No: "no",
      yes: "Yes",
      Yes: "yes",
    }[key] ?? ""),
  education: (key: string) =>
    ({
      diploma: "Diploma",
      Diploma: "diploma",
      "high.diploma": "High diploma",
      "High diploma": "high.diploma",
      nan: "nan",
      secondary: "Secondary",
      Secondary: "secondary",
      bachelor: "Bachelor",
      Bachelor: "bachelor",
      primary: "Primary",
      Primary: "primary",
      master: "Master",
      Master: "master",
      elementary: "Elementary",
      Elementary: "elementary",
      "sec.cert": "Sec cert",
      "Sec cert": "sec.cert",
      phd: "Phd",
      Phd: "phd",
      illiterate: "Illiterate",
      Illiterate: "illiterate",
      professor: "Professor",
      Professor: "professor",
    }[key] ?? ""),
  employment_levels: (key: string) =>
    ({
      nan: "nan",
      other: "OTHER",
      OTHER: "other",
      employee: "EMPLOYEE",
      EMPLOYEE: "employee",
      "sec.head": "SEC HEAD",
      "SEC HEAD": "sec.head",
      corporal: "CORPORAL",
      CORPORAL: "corporal",
      military: "MILITARY",
      MILITARY: "military",
      manager: "MANAGER",
      MANAGER: "manager",
      "admin.emp": "ADMIN EMP",
      "ADMIN EMP": "admin.emp",
      teacher: "TEACHER",
      TEACHER: "teacher",
      "lance.corp": "LANCE CORP",
      "LANCE CORP": "lance.corp",
      "nurs.asst": "NUR ASST",
      "NUR ASST": "nurs.asst",
      "gen.mgr": "GEN MGR",
      "GEN MGR": "gen.mgr",
      "exec.mgr": "EXEC MGR",
      "EXEC MGR": "exec.mgr",
      "admin.mgr": "ADMIN MGR",
      "ADMIN MGR": "admin.mgr",
      "asst.offr": "ASST OFFR",
      "ASST OFFR": "asst.offr",
      supervisor: "SUPERVISOR",
      SUPERVISOR: "supervisor",
      driver: "DRIVER",
      DRIVER: "driver",
      // "lieut.1st": "LIEUT 1ST",
      // "LIEUT 1ST": "LIEUT 1ST",
      // "edit.chief",
      // "asst.mgr",
      soldier: "SOLDIER",
      SOLDIER: "soldier",
      guard: "GUARD",
      GUARD: "guard",
      // "lieut.2nd",
      // "gm",
      // "private",
      police: "POLICE",
      POLICE: "police",
      ceo: "CEO",
      CEO: "ceo",
      judge: "JUDGE",
      JUDGE: "judge",
      sargent: "SARGENT",
      SARGENT: "sargent",
      worker: "WORKER",
      WORKER: "worker",
      "self.empl": "SELF EMPL",
      "SELF EMPL": "self.empl",
      // "sarg.1st",
      captain: "CAPTAIN",
      CAPTAIN: "captain",
      "office.mgr": "OFFICE MGR",
      "OFFICE MGR": "office.mgr",
      // "tea.boy",
      // "mgr.asst",
      // "admin.spec",
      // "mili.1st",
      partner: "PARTNER",
      PARTNER: "partner",
      // "lan.cor1st",
      minister: "MINISTER",
      MINISTER: "minister",
      lieutenant: "LIEUTENANT",
      LIEUTENANT: "lieutenant",
      // "legal.adv",
      // "sold.1st",
      "band.work": "BAND WORK",
      "BAND WORK": "band.work",
      general: "GENERAL",
      GENERAL: "general",
      // "tech.adv",
      "sec.super": "SEC SUPER",
      "SEC SUPER": "sec.super",
      // "first.off",
      // "deputy.ceo",
      // "fin.adv",
      colonel: "COLONEL",
      COLONEL: "colonel",
      // "ast.hd.sec",
      pupil: "PUPIL",
      PUPIL: "pupil",
      cfo: "CFO",
      CFO: "cfo",
      "grp.head": "GRP HEAD",
      "GRP HEAD": "grp.head",
      // "lan.cor2nd",
      // "dip.advsr",
      // "exec.secr",
      "lieut.col": "LIEUT COL",
      "LIEUT COL": "lieut.col",
      major: "MAJOR",
      MAJOR: "major",
      // "resr.asst": "RESR ASST",
      // "second.off":"SECOND OFF"
    }[key] ?? ""),
  userRole: (key: string) =>
    ({
      admin: "Admin",
      Admin: "admin",
      Root: "root",
      root: "Root",
      expert: "Credit expert",
      "Credit expert": "expert",
    }[key] ?? ""),
};

export default transcript;
