import React from "react";
import * as math from "mathjs";

import Range from "components/range";
import rangebox from "./range-box";
import styles from "./range-box.module.scss";

const RangeBox: React.FC<rangebox.props> = ({
  value,
  setValue,
  min = 0,
  max = 100,
}) => {
  return (
    <div className={styles.rangeBox}>
      <Range min={1} max={max} step={1} value={value} setValue={setValue} />
      <input
        type="number"
        className={styles.input}
        value={value}
        step={1}
        min={min}
        max={max}
        onFocus={(event) => event.target.select()}
        onChange={(event) =>
          setValue(math.fix(math.max(0, math.min(max, +event.target.value)), 2))
        }
      />
    </div>
  );
};

export default RangeBox;
