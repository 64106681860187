import isDev from "./is-dev";

// const devURL = process.env.REACT_APP_BASE_URL ?? "https://ahli-jb.uaenorth.cloudapp.azure.com";
// const devURL = process.env.REACT_APP_BASE_URL ?? "http://20.203.38.200:9000";
const devURL =
  process.env.REACT_APP_BASE_URL ??
  "https://ahli-jb.uaenorth.cloudapp.azure.com/python";

const prodURL =
  process.env.REACT_APP_TEST_DEPLOY === "1"
    ? "https://ahli-jb.uaenorth.cloudapp.azure.com/python"
    : process.env.REACT_APP_BASE_URL ??
      "https://ahli-jb.uaenorth.cloudapp.azure.com";

const baseURL = isDev ? devURL : prodURL;

export default baseURL;
