import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import AppRoutes from "routes";
import store from "store";
import "global.scss";
import { ToastContainer } from "react-toastify";

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
    <ToastContainer />
  </Provider>
);

export default App;
