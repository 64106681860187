import React from "react";

import Input from "components/input";
import Button from "components/button";
import request from "request";
import styles from "./change-password.module.scss";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

const Modal = ({ onClose }: { onClose: () => void }) => {
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmedNewPassword, setConfirmedNewPassword] = React.useState("");

  const [pending, setPending] = React.useState(false);
  const [validError, setValidError] = React.useState<{
    name: "oldPassword" | "newPassword" | "confirmedNewPassword";
    value: string;
  } | null>(null);

  const handleCreate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (oldPassword.trim().length === 0) {
      return setValidError({
        name: "oldPassword",
        value: "Required field",
      });
    } else if (newPassword.trim().length === 0) {
      return setValidError({
        name: "newPassword",
        value: "Required field",
      });
    } else if (confirmedNewPassword !== newPassword) {
      return setValidError({
        name: "confirmedNewPassword",
        value: "Please confirm the password correctly",
      });
    } else setValidError(null);
    setPending(true);
    request
      .put("/updatepassword", {
        oldPassword,
        newPassword,
      })
      .then(() => {
        toast.success("Password successfully changed!");
        onClose();
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 400) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          for (const [_, value] of Object.entries(
            (e.response.data as any)?.errors
          )) {
            toast.error((value as string[])[0]);
          }
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <div className={styles.modal} onMouseDown={onClose}>
      <form
        className={styles.modalWrap}
        onMouseDown={(event) => event.stopPropagation()}
        onSubmit={handleCreate}
      >
        <div className={styles.title}>Change your password</div>
        <div className={styles.inputs}>
          <div className={styles.input}>
            <Input
              title="Old password"
              type="password"
              value={oldPassword}
              onInput={(value) => setOldPassword(value)}
            />
            {validError?.name === "oldPassword" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="New password"
              type="password"
              value={newPassword}
              onInput={(value) => setNewPassword(value)}
            />
            {validError?.name === "newPassword" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
          <div className={styles.input}>
            <Input
              title="Confirm new password"
              type="password"
              value={confirmedNewPassword}
              onInput={(value) => setConfirmedNewPassword(value)}
            />
            {validError?.name === "confirmedNewPassword" && (
              <div className={styles.inputError}>{validError.value}</div>
            )}
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <Button color="orange" onClick={onClose}>
            Cancel
          </Button>
          <Button type="submit" color="green" pending={pending}>
            Change
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Modal;
