import classNames from "classnames";
import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { useSelector } from "store/hooks";
import Modal from "./components/change-password";
import modalStyles from "./components/change-password.module.scss";
import styles from "./main.module.scss";

const MainLayout = () => {
  const navigate = useNavigate();
  const [profileOpened, setProfileOpened] = React.useState(false);
  const [changePasswordModal, setChangePasswordModal] = React.useState(false);
  const user = useSelector((state) => state.user);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return (
    <>
      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.headerContainer}>
            <Link to="/" className={styles.title}>
              Ahli bank | zypl.score
            </Link>
            <div
              className={classNames(
                styles.profileDropdown,
                profileOpened && styles.active
              )}
            >
              <button
                className={styles.profileDropdown__label}
                onClick={() => setProfileOpened(!profileOpened)}
              >
                {user.email}
              </button>
              {profileOpened && (
                <div
                  className={styles.cover}
                  onMouseDown={() => setProfileOpened(false)}
                />
              )}
              <CSSTransition
                unmountOnExit
                timeout={80}
                in={profileOpened}
                classNames={{
                  enter: styles.enter,
                  enterActive: styles.enterActive,
                  exit: styles.exit,
                  exitActive: styles.exitActive,
                }}
              >
                <div className={styles.profileDropdown__menu}>
                  {user.role !== "expert" && (
                    <>
                      <Link
                        onClick={() => setProfileOpened(false)}
                        to="/users"
                        className={styles.profileDropdown__item}
                      >
                        User list
                      </Link>
                      <Link
                        onClick={() => setProfileOpened(false)}
                        to="/settings"
                        className={styles.profileDropdown__item}
                      >
                        Settings
                      </Link>
                      <Link
                        onClick={() => setProfileOpened(false)}
                        to="/action-history"
                        className={styles.profileDropdown__item}
                      >
                        Action history
                      </Link>
                    </>
                  )}
                  <button
                    className={styles.profileDropdown__item}
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </div>
              </CSSTransition>
            </div>
          </div>
        </div>
        <Outlet />
      </div>
      <CSSTransition
        in={changePasswordModal}
        timeout={150}
        unmountOnExit
        classNames={{
          enter: modalStyles.modalEnter,
          enterActive: modalStyles.modalEnterActive,
          exit: modalStyles.modalExit,
          exitActive: modalStyles.modalExitActive,
        }}
      >
        <Modal onClose={() => setChangePasswordModal(false)} />
      </CSSTransition>
    </>
  );
};

export default MainLayout;
