import classNames from "classnames";
import Icon from "icons";
import { useSelector } from "store/hooks";
import styles from "./result-box.module.scss";

const ResultBox = () => {
  const contextApplication = useSelector((state) => state.application);

  if (contextApplication.data?.is_approved === null) return <></>;
  return (
    <div className={styles.resultBox}>
      <div className={styles.resultBox_title}>Application decision</div>
      {contextApplication.data?.is_approved !== null && (
        <div className={styles.finalResult}>
          <Icon
            size={120}
            name={
              contextApplication.data?.is_approved
                ? "tickCircle"
                : "closeFilled"
            }
            className={classNames(
              contextApplication.data?.is_approved ? styles.green : styles.red
            )}
          />
          <div
            className={classNames(
              styles.finalResultText,
              contextApplication.data?.is_approved ? styles.green : styles.red
            )}
          >
            {contextApplication.data?.is_approved ? "Approved" : "Rejected"}
          </div>
        </div>
      )}
      <div className={styles.resultBox_resultText}>
        {contextApplication.data?.scoring_log?.is_approved !== null && (
          <div className={styles.item}>
            <div className={styles.key}>
              Scoring ({contextApplication.data?.scoring_log?.prediction}%):
            </div>
            <div
              className={classNames(
                styles.value,
                contextApplication.data?.scoring_log === null
                  ? styles.grey
                  : contextApplication.data?.scoring_log?.is_approved
                  ? styles.green
                  : styles.red
              )}
            >
              {contextApplication.data?.scoring_log === null
                ? "No decision, yet"
                : contextApplication.data?.scoring_log?.is_approved
                ? "Approved"
                : "Rejected"}
            </div>
          </div>
        )}
        {/* {contextApplication.data?.dbr_log?.is_approved !== null && (
          <div className={styles.item}>
            <div className={styles.key}>DBR:</div>
            <div
              className={classNames(
                styles.value,
                contextApplication.data?.dbr_log?.is_approved
                  ? styles.green
                  : styles.red
              )}
            >
              {contextApplication.data?.dbr_log?.is_approved
                ? "Approved"
                : "Rejected"}
            </div>
          </div>
        )} */}
        {/* {contextApplication.data?.aecb_score_log?.is_approved !== null && (
          <div className={styles.item}>
            <div className={styles.key}>AECB:</div>
            <div
              className={classNames(
                styles.value,
                contextApplication.data?.aecb_score_log?.is_approved
                  ? styles.green
                  : styles.red
              )}
            >
              {contextApplication.data?.aecb_score_log?.is_approved
                ? "Approved"
                : "Rejected"}
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default ResultBox;
