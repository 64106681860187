import {
  Document,
  Font,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";
import { useNavigate } from "react-router-dom";

import ResultBox from "components/result-box";
import global from "global";
import request from "request";
import { useSelector } from "store/hooks";
import transcript from "utils/transcript";
import myFont from "./font/Arial-Light.ttf";
import styles from "./result.module.scss";

Font.register({
  family: "Arial",
  src: myFont,
});

const pdfStyles = StyleSheet.create({
  page: {
    fontFamily: "Arial",
    padding: "10px",
  },
  section: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  heading: {
    fontSize: "24px",
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  text: {
    fontSize: "14px",
  },
  table: {
    backgroundColor: "#fff",
  },
  tr: {
    display: "flex",
    flexDirection: "row",
  },
  td: {
    border: "1px solid #000",
    width: "100%",
    padding: "5px 10px",
    fontSize: "14px",
  },
  result: {
    fontSize: "18px",
    fontWeight: 700,
  },
  bottom: {
    margin: "10px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

const Result = () => {
  const [data, setData] = React.useState();
  const navigate = useNavigate();
  const contextApplication = useSelector((state) => state.application);

  React.useEffect(() => {
    if (contextApplication.data?.is_approved === null) navigate("/");
  }, [contextApplication, navigate]);

  React.useEffect(() => {
    request
      .get(`/applications/${contextApplication.data?.id}/shap`)
      .then((res) => setData(res.data));
  }, [contextApplication.data?.id]);

  return (
    <div className={styles.result}>
      <div className={styles.fields}>
        <div className={styles.pdf}>
          <PDFViewer style={{ width: "100%", height: "calc(100vh - 150px)" }}>
            <Pdf contextApplication={contextApplication} />
          </PDFViewer>
        </div>
        <div className={styles.creditDataBox}>
          <div className={styles.title}>Decision Shapley Values</div>
          {data && (
            <iframe
              style={{ width: "100%" }}
              title="shap"
              srcDoc={data}
            ></iframe>
          )}
          <div
            className={styles.gpt_text}
            dangerouslySetInnerHTML={{
              __html: String(
                contextApplication.data?.scoring_log?.gpt_explanation
                  .replaceAll("\n", "<br/>")
                  .replaceAll(
                    "\t",
                    `<span style="display: inline-block; width: 20px"></span>`
                  )
              ),
            }}
          />
        </div>
      </div>
      <div className={styles.resultBoxGroup}>
        <ResultBox />
      </div>
    </div>
  );
};

const Pdf = ({
  contextApplication,
}: {
  contextApplication: global.contextApplication;
}) => {
  return (
    <Document>
      {contextApplication.data?.scoring_log && (
        <>
          <Page style={pdfStyles.page}>
            <View style={pdfStyles.heading}>
              <Text>Scoring report</Text>
            </View>
            <View style={pdfStyles.section}>
              <Text style={pdfStyles.text}>
                Date/Time:{" "}
                {new Date(
                  contextApplication.data?.created_at ?? ""
                ).toLocaleString()}
              </Text>
              <Text style={pdfStyles.text}>
                Application: {contextApplication.data?.id}
              </Text>
              <View style={pdfStyles.table}>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Customer ID</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.customerId}</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Application ID</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.applicationId}</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Amount</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.loanAmount} JOD</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Interest rate</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.interestRate}%</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Branch</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.scoring_log?.branch}</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Gender</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.gender(
                        contextApplication.data?.scoring_log?.gender
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Family status</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.familyStatus(
                        contextApplication.data?.scoring_log?.familyStatus
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Employment sphere</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.employment_spheres(
                        contextApplication.data?.scoring_log?.employmentSphere
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Loan duration</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.loanDuration}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Collateral</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.collateral(
                        contextApplication.data?.scoring_log?.collateral
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Co borrower</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.coBorrower(
                        contextApplication.data?.scoring_log?.collateral
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Type of product</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.productType}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Employment level</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {transcript.employment_levels(
                        contextApplication.data?.scoring_log?.employmentLevel
                      )}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Age</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.scoring_log?.age}</Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Monthly income</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.monthlyIncome} JOD
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Work experience</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.workExperience}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Dependents</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.dependents}
                    </Text>
                  </View>
                </View>
                {/* <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Granted limit AM</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.grantedLimitAM}
                    </Text>
                  </View>
                </View> */}
                {contextApplication.data?.scoring_log?.is_approved !== null && (
                  <View style={pdfStyles.tr}>
                    <View style={pdfStyles.td}>
                      <Text>Scoring prediction</Text>
                    </View>
                    <View style={pdfStyles.td}>
                      <Text>
                        {contextApplication.data?.scoring_log?.prediction}%
                      </Text>
                    </View>
                  </View>
                )}
                {/* <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>AECB</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data.aecb_score_log?.value}</Text>
                  </View>
                </View> */}
                {contextApplication.data.ltv_score_log && (
                  <View style={pdfStyles.tr}>
                    <View style={pdfStyles.td}>
                      <Text>LTV</Text>
                    </View>
                    <View style={pdfStyles.td}>
                      <Text>
                        {contextApplication.data.ltv_score_log?.value} %
                      </Text>
                    </View>
                  </View>
                )}
              </View>
              <View style={pdfStyles.bottom}>
                <Text style={pdfStyles.result}>
                  Result:{" "}
                  {contextApplication.data?.is_approved
                    ? "Approved"
                    : "Rejected"}{" "}
                </Text>
                <Text style={pdfStyles.result}>
                  Threshold:{" "}
                  {Number(contextApplication.data?.scoring_log?.threshold) *
                    100}
                  %
                </Text>
              </View>
            </View>
          </Page>
          {/* <Page style={pdfStyles.page}>
            <View style={pdfStyles.heading}>
              <Text>Macro variables</Text>
            </View>
            <View style={pdfStyles.section}>
              <Text style={pdfStyles.text}>
                Application: {contextApplication.data?.id}
              </Text>
              <View style={pdfStyles.table}>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Inflation rate</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.inflation_rate}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>Manufacturing PMI</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>
                      {contextApplication.data?.scoring_log?.manufacturing_PMI}
                    </Text>
                  </View>
                </View>
                <View style={pdfStyles.tr}>
                  <View style={pdfStyles.td}>
                    <Text>CPI</Text>
                  </View>
                  <View style={pdfStyles.td}>
                    <Text>{contextApplication.data?.scoring_log?.CPI}</Text>
                  </View>
                </View>
              </View>
            </View>
          </Page> */}
        </>
      )}
    </Document>
  );
};

export default React.memo(Result, () => true);
