import classNames from "classnames";
import Button from "components/button";
import ButtonLoader from "components/button-loader";
import Input from "components/input";
import Icon from "icons";
import * as math from "mathjs";
import React from "react";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import request from "request";
import prettierNumber from "utils/prettier-number";
import downloadExcel from "../../utils/download-xlsx";
import Modal from "./components/create-modal";
import modalStyles from "./components/create-modal.module.scss";
import home from "./home";
import styles from "./home.module.scss";

const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
const lastDayOfYear = new Date(new Date().getFullYear(), 11, 31);

const Home = () => {
  const [from, setFrom] = React.useState<Date>(firstDayOfYear);
  const [to, setTo] = React.useState<Date>(lastDayOfYear);
  const [page, setPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(1);
  const [newApplicationModal, setNewApplicationModal] = React.useState(false);
  const [pending, setPending] = React.useState(true);
  const [exportExcelPending, setExportExcelPending] = React.useState(false);
  const resultRef = React.useRef<HTMLDivElement | null>(null);
  const [data, setData] = React.useState<home.result[]>([]);

  const handleLoadData = React.useCallback(
    async (f: Date, t: Date, p: number) => {
      const fd = f.toLocaleDateString("en-GB").split("/").reverse().join("-");
      const td = t.toLocaleDateString("en-GB").split("/").reverse().join("-");
      // setPage(1);
      setPending(true);
      resultRef.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      request
        .get(`/applications/history?fd=${fd}&td=${td}&page=${p}`)
        .then((res) => res.data)
        .then((d) => {
          setData(d.records);
          setRowCount(d.total_count);
        })
        .finally(() => setPending(false));
    },
    []
  );

  const handleChangePage = () => {
    // console.log(value, "value");
  };

  console.log(page);

  const handleClear = () => {
    setFrom(firstDayOfYear);
    setTo(lastDayOfYear);
    setPage(1);
    handleLoadData(firstDayOfYear, lastDayOfYear, 1);
  };

  const exportExcelFile = () => {
    setPending(true);
    const fd = from.toLocaleDateString("en-GB").split("/").reverse().join("-");
    const td = to.toLocaleDateString("en-GB").split("/").reverse().join("-");
    request
      .get(`/applications/history?fd=${fd}&td=${td}`)
      .then((res) => res.data)
      .then((d) => {
        let dateForExport = d.records;
        const jsonForExcel: any = {};
        for (let i = 0; i < dateForExport.length; i++)
          jsonForExcel[`${i + 1}`] = {
            "Application ID": dateForExport[i]?.id,
            Email: dateForExport[i]?.created_by.email,
            "Create date": dateForExport[i]?.created_at,
            Amount: dateForExport[i]?.loanAmount + "JOD",
            "Loan duration": dateForExport[i]?.scoring_log?.loanDuration,
            "Interest rate": dateForExport[i]?.interestRate + "%",
            "Scoring threshold": `${(
              dateForExport[i]?.scoring_log?.threshold * 100 ?? 0
            ).toFixed(2)}%`,
            Branch: dateForExport[i]?.scoring_log?.branch,
            Gender: dateForExport[i]?.scoring_log?.gender,
            "Family status": dateForExport[i]?.scoring_log?.familyStatus,
            "Employment sphere":
              dateForExport[i]?.scoring_log?.employmentSphere,
            Collateral: dateForExport[i]?.scoring_log?.collateral,
            "Co borrower": dateForExport[i]?.scoring_log?.coBorrower,
            "Type of product": dateForExport[i]?.scoring_log?.productType,
            "Employment level": dateForExport[i]?.scoring_log?.employmentLevel,
            Education: dateForExport[i]?.scoring_log?.education,
            Age: dateForExport[i]?.scoring_log?.age,
            "Monthly income":
              dateForExport[i]?.scoring_log?.monthlyIncome + "JOD",
            "Work experience": dateForExport[i]?.scoring_log?.workExperience,
            Dependents: dateForExport[i]?.scoring_log?.dependents,
            "Scoring prediction": dateForExport[i]?.scoring_log?.prediction,
          };
        const sheetData = Object.values(jsonForExcel);
        if (data.length === 0) return;
        downloadExcel(sheetData);
      })
      .finally(() => setPending(false));
  };

  React.useEffect(() => {
    handleLoadData(firstDayOfYear, lastDayOfYear, 1);
  }, [handleLoadData]);

  return (
    <div className={styles.results} ref={resultRef}>
      <div className={styles.container}>
        <div className={styles.tools}>
          <div className={styles.filterBox}>
            <div className={styles.input}>
              <Input
                title="From"
                value={from}
                type="date"
                onSetDate={(d) => setFrom(d)}
              />
            </div>
            <div className={styles.input}>
              <Input
                title="To"
                value={to}
                type="date"
                onSetDate={(d) => setTo(d)}
              />
            </div>
            <div className={styles.buttonGroup}>
              <Button
                pending={pending}
                onClick={() => {
                  setPage(1);
                  handleLoadData(from, to, 1);
                }}
              >
                Filter
              </Button>
              <Button pending={pending} color="red" onClick={handleClear}>
                Clear
              </Button>
            </div>
          </div>
          <div className={styles.buttonGroup}>
            <Button
              pending={exportExcelPending || pending}
              color="orange"
              onClick={exportExcelFile}
            >
              Export .XLSX file
            </Button>
            <Button color="green" onClick={() => setNewApplicationModal(true)}>
              New application
            </Button>
          </div>
        </div>
        {pending && (
          <div className={styles.tableLoading}>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
          </div>
        )}
        {!pending && data.length !== 0 && (
          <div className={styles.table}>
            {data.map((item) => (
              <Link
                to={`application/${item.id}/scoring`}
                className={styles.tableRowWrap}
                key={item.id}
              >
                <div className={styles.tableRow}>
                  <div className={styles.item}>
                    <span>Customer ID</span> {item.customerId}
                  </div>
                  <div className={styles.item}>
                    <span>Application ID</span> {item.applicationId}
                  </div>
                  <div className={styles.item}>
                    <span>Expert</span>
                    <div className={styles.email}>{item?.created_by.email}</div>
                  </div>
                  <div className={styles.item}>
                    <span>Create date</span>
                    {new Date(item.created_at).toLocaleString()}
                  </div>
                  <div className={styles.item}>
                    <span>Amount</span>
                    {prettierNumber(item.loanAmount)} JOD
                  </div>
                  <div className={styles.item}>
                    <span>Interest rate</span>
                    {item.interestRate}%
                  </div>
                  <div
                    className={classNames(
                      styles.item,
                      item.is_approved === true && styles.green,
                      item.is_approved === false &&
                        item.scoring_log &&
                        styles.red
                    )}
                  >
                    <span>Result</span>
                    {item.scoring_log === null
                      ? "Draft"
                      : item.is_approved
                      ? "Approved"
                      : "Rejected"}
                  </div>
                  <div className={styles.item}>
                    <span>Probability of approval</span>
                    {item.scoring_log?.prediction
                      ? `${(item.scoring_log?.prediction).toFixed(2)}%`
                      : "–"}
                  </div>
                  <div className={styles.item}>
                    <span>Threshold</span>
                    {item.scoring_log?.threshold
                      ? `${item.scoring_log?.threshold * 100}%`
                      : "–"}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
        {!pending && data.length === 0 && (
          <div className={styles.tableIsEmptyInfo}>No applications</div>
        )}
        {rowCount > 15 && (
          <div className={styles.pagination}>
            <button
              className={classNames(styles.arrow, page <= 1 && styles.disabled)}
              onClick={() => {
                const v = math.max(page - 1, 1);
                setPage(v);
                handleLoadData(from, to, v);
                // handleChangePage()
              }}
            >
              <Icon name="arrowLeft" />
            </button>
            <div className={styles.label}>
              {page} / {math.ceil(rowCount / 15)}
            </div>
            <button
              className={classNames(
                styles.arrow,
                page * 15 >= rowCount && styles.disabled
              )}
              onClick={() => {
                const v = math.min(page + 1, math.ceil(rowCount / 15));
                setPage(v);
                handleLoadData(from, to, v);
                // handleChangePage()
              }}
            >
              <Icon name="arrowRight" />
            </button>
          </div>
        )}
      </div>
      <CSSTransition
        in={!!newApplicationModal}
        timeout={150}
        unmountOnExit
        classNames={{
          enter: modalStyles.modalEnter,
          enterActive: modalStyles.modalEnterActive,
          exit: modalStyles.modalExit,
          exitActive: modalStyles.modalExitActive,
        }}
      >
        <Modal onClose={() => setNewApplicationModal(false)} />
      </CSSTransition>
    </div>
  );
};

export default Home;
