import { isAxiosError } from "axios";
import Button from "components/button";
import Input from "components/input";
import Loading from "components/loading";
import ResultBox from "components/result-box";
import global from "global";
import React from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import request from "request";
import { useDispatch, useSelector } from "store/hooks";
import {
  branch,
  coBorrowers,
  collaterals,
  educations,
  employment_levels,
  employment_spheres,
  family_statuses,
  genders,
  productType
} from "utils/constants";
import prettierNumber from "utils/prettier-number";
import transcript from "utils/transcript";
import styles from "./scoring.module.scss";

const initialValidationState = {
  age: "",
  monthlyIncome: "",
  typeOfProduct: "",
  duration_at_disbursement_months: ""
};

const Scoring = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const contextApplication = useSelector((state) => state.application);
  const dispatch = useDispatch();
  const [macrodata, setMacrodata] = React.useState<global.macrodata>();

  const [pending, setPending] = React.useState(false);
  const [formValid, setFormValid] = React.useState(false);
  const [validationError, setValidationError] = React.useState(initialValidationState);

  const [form, setForm] = React.useState({
    branch: "",
    gender: "",
    familyStatus: "",
    employmentSphere: "",
    collateral: "",
    coBorrower: "",
    productType: "",
    employmentLevel: "",
    loanDuration: "",
    grantedLimitAM: "",
    age: "",
    monthlyIncome: "",
    workExperience: "",
    dependents: "",
    education: "",
    interestRate: ""
    // aecb: "",
  });

  const handleCalculate = () => {
    let timeout: NodeJS.Timeout;

    setPending(true);

    request
      .post(`/applications/${id}/scoring`, {
        branch: form.branch,
        gender: transcript.gender(form.gender),
        familyStatus: transcript.familyStatus(form.familyStatus),
        employmentSphere: transcript.employment_spheres(form.employmentSphere),
        collateral: transcript.collateral(form.collateral),
        coBorrower: transcript.coBorrower(form.coBorrower),
        productType: form.productType,
        employmentLevel: transcript.employment_levels(form.employmentLevel),
        education: transcript.education(form.education),
        loanDuration: Number(form.loanDuration),
        interestRate: Number(contextApplication.data?.interestRate),
        age: Number(form.age),
        monthlyIncome: Number(form.monthlyIncome),
        workExperience: Number(form.workExperience),
        dependents: Number(form.dependents),
        grantedLimitAM: Number(contextApplication.data?.loanAmount)
      })
      .then((res) => res.data)
      .then(() => {
        timeout = setTimeout(() => dispatch.application.FETCH_APPLICATION_BY_ID(id), 1000);
      })
      .catch((e: unknown) => {
        if (!isAxiosError(e)) return;

        if (e.response?.status === 409) {
          toast.error(e.response.data.detail);
        } else if (e.response?.status === 422) {
          toast.error(e?.response?.data?.detail[0]?.msg);
        }
      })
      .finally(() => setPending(false));
    return () => clearTimeout(timeout);
  };

  React.useEffect(() => {
    if (user.role === "expert") return;
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((d) => setMacrodata(d));
  }, [user.role]);

  React.useEffect(() => {
    setFormValid(
      // form.location.key !== "" &&
      form.branch !== "" &&
        form.gender !== "" &&
        form.familyStatus !== "" &&
        form.employmentSphere !== "" &&
        form.collateral !== "" &&
        form.coBorrower !== "" &&
        form.productType !== "" &&
        form.employmentLevel !== "" &&
        form.loanDuration !== "" &&
        // form.grantedLimitAM !== "" &&
        form.age !== "" &&
        form.monthlyIncome !== "" &&
        form.workExperience !== "" &&
        form.education !== "" &&
        form.dependents !== ""
      // form.aecb !== ""
    );
  }, [form]);

  if (user.role !== "expert" && !macrodata) return <></>;
  return (
    <div className={styles.home}>
      <div className={styles.fields}>
        <div className={styles.creditDataBox}>
          <div className={styles.title}>Credit data</div>
          <div className={styles.list}>
            <div className={styles.item}>
              <div className={styles.key}>Customer ID</div>
              <div className={styles.value}>{contextApplication.data?.customerId}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Application ID</div>
              <div className={styles.value}>{contextApplication.data?.applicationId}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Amount</div>
              <div className={styles.value}>
                {prettierNumber(contextApplication.data?.loanAmount ?? 0)} JOD
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.key}>Interest rate</div>
              <div className={styles.value}>{contextApplication.data?.interestRate}%</div>
            </div>
            {(user.role !== "expert" || contextApplication.data?.scoring_log?.threshold) && (
              <div className={styles.item}>
                <div className={styles.key}>Scoring threshold</div>
                <div className={styles.value}>
                  {contextApplication.data?.scoring_log?.threshold
                    ? Number(contextApplication.data?.scoring_log?.threshold) * 100
                    : (Number(macrodata?.threshold) * 100).toFixed(0)}{" "}
                  %
                </div>
              </div>
            )}
            {/* <div className={styles.item}>
              <div className={styles.key}>DBR threshold</div>
              <div className={styles.value}>
                {contextApplication.data?.dbr_log
                  ? contextApplication.data?.dbr_log?.dbr_threshold
                  : macrodata?.dbr_threshold}{" "}
                %
              </div>
            </div> */}
            {/* <div className={styles.item}>
              <div className={styles.key}>AECB limit</div>
              <div className={styles.value}>
                {contextApplication.data?.aecb_score_log?.aecb_score_limit ??
                  macrodata?.aecb_score_limit}
              </div>
            </div> */}
            {/* <div className={styles.item}>
              <div className={styles.key}>LTV limit</div>
              <div className={styles.value}>
                {contextApplication.data?.ltv_score_log?.ltv_threshold ??
                  macrodata?.ltv_threshold}
                %
              </div>
            </div> */}
          </div>
        </div>
        {!!contextApplication.data?.scoring_log && (
          <>
            <div className={styles.creditDataBox}>
              <div className={styles.title}>Customer data</div>
              <div className={styles.list}>
                <div className={styles.item}>
                  <div className={styles.key}>Branch</div>
                  <div className={styles.value}>{contextApplication.data.scoring_log.branch}</div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Gender</div>
                  <div className={styles.value}>
                    {transcript.gender(contextApplication.data.scoring_log.gender)}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Family status</div>
                  <div className={styles.value}>
                    {transcript.familyStatus(contextApplication.data.scoring_log.familyStatus)}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Employment sphere</div>
                  <div className={styles.value}>
                    {transcript.employment_spheres(
                      contextApplication.data.scoring_log.employmentSphere
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Collateral</div>
                  <div className={styles.value}>
                    {transcript.collateral(contextApplication.data.scoring_log.collateral)}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Co borrower</div>
                  <div className={styles.value}>
                    {transcript.coBorrower(contextApplication.data.scoring_log.coBorrower)}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Type of product</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.productType}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Employment level</div>
                  <div className={styles.value}>
                    {transcript.employment_levels(
                      contextApplication.data.scoring_log.employmentLevel
                    )}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Education</div>
                  <div className={styles.value}>
                    {transcript.education(contextApplication.data.scoring_log.education)}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Loan duration</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.loanDuration}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Age</div>
                  <div className={styles.value}>{contextApplication.data.scoring_log.age}</div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Monthly income</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.monthlyIncome} JOD
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Work experience</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.workExperience}
                  </div>
                </div>
                <div className={styles.item}>
                  <div className={styles.key}>Dependents</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.dependents}
                  </div>
                </div>
                {/* <div className={styles.item}>
                  <div className={styles.key}>Granted limit AM</div>
                  <div className={styles.value}>
                    {contextApplication.data.scoring_log.grantedLimitAM}
                  </div>
                </div> */}
                {contextApplication.data?.scoring_log?.is_approved !== null && (
                  <div className={styles.item}>
                    <div className={styles.key}>Scoring prediction</div>
                    <div className={styles.value}>
                      {contextApplication.data?.scoring_log?.prediction}%
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {!contextApplication.data?.scoring_log && (
          <>
            <div className={styles.fieldGroup}>
              <div className={styles.fieldTitle}>Customer data</div>
              <div className={styles.fieldList}>
                {/* <Input
                  hasFilterText
                  title="Location"
                  id="location"
                  value={form.location.key}
                  type="select"
                  options={locations.map((location) => ({
                    key: location,
                    value: location,
                  }))}
                  onSelect={(option) => setForm({ ...form, location: option })}
                /> */}
                <Input
                  hasFilterText
                  title="Branch"
                  id="branch"
                  value={form.branch}
                  type="select"
                  isArabic
                  options={branch}
                  onSelect={(option) => setForm({ ...form, branch: option })}
                />
                <Input
                  title="Gender"
                  id="gender"
                  value={form.gender}
                  type="select"
                  options={genders}
                  onSelect={(option) => setForm({ ...form, gender: option })}
                />
                <Input
                  title="Family status"
                  id="familyStatus"
                  value={form.familyStatus}
                  type="select"
                  options={family_statuses}
                  onSelect={(option) => setForm({ ...form, familyStatus: option })}
                />
                <Input
                  title="Employment sphere"
                  id="employmentSphere"
                  value={form.employmentSphere}
                  type="select"
                  options={employment_spheres}
                  onSelect={(option) => setForm({ ...form, employmentSphere: option })}
                />
                <Input
                  title="Education"
                  id="education"
                  value={form.education}
                  type="select"
                  options={educations}
                  onSelect={(option) => setForm({ ...form, education: option })}
                />
                <Input
                  title="Collateral"
                  id="collateral"
                  value={form.collateral}
                  type="select"
                  options={collaterals}
                  onSelect={(option) => setForm({ ...form, collateral: option })}
                />
                <Input
                  title="Co borrower"
                  id="coBorrower"
                  value={form.coBorrower}
                  type="select"
                  options={coBorrowers}
                  onSelect={(option) => setForm({ ...form, coBorrower: option })}
                />
                <Input
                  title="Type of product"
                  id="type_of_product"
                  value={form.productType}
                  type="select"
                  options={productType}
                  isArabic
                  hasFilterText
                  error={validationError.typeOfProduct}
                  onSelect={(option) => setForm({ ...form, productType: option })}
                />
                <Input
                  hasFilterText
                  title="Employment level"
                  id="employmentLevel"
                  value={form.employmentLevel}
                  type="select"
                  options={employment_levels}
                  onSelect={(option) => setForm({ ...form, employmentLevel: option })}
                />
                <Input
                  title="Loan duration"
                  value={form.loanDuration}
                  type="float"
                  onInput={(value) => setForm({ ...form, loanDuration: value })}
                  step={0.1}
                  min={1}
                  max={300}
                  error={validationError.duration_at_disbursement_months}
                  placeholder={`min: 0  max: ${300}`}
                />
                {/* <Input
                  title="Granted limit AM"
                  value={form.grantedLimitAM}
                  type="integer"
                  onInput={(value) =>
                    setForm({
                      ...form,
                      grantedLimitAM: value,
                    })
                  }
                  step={100}
                  min={1}
                  max={141000}
                  placeholder={`min: 0  max: ${141000}`}
                /> */}
                <Input
                  title="Age"
                  value={form.age}
                  type="integer"
                  onInput={(value) => setForm({ ...form, age: value })}
                  step={1}
                  min={18}
                  max={80}
                  error={validationError.age}
                  placeholder={`min: 18  max: ${80}`}
                />
                <Input
                  title="Monthly income"
                  value={form.monthlyIncome}
                  type="float"
                  onInput={(value) => setForm({ ...form, monthlyIncome: value })}
                  step={100}
                  min={0}
                  max={30000}
                  error={validationError.monthlyIncome}
                  placeholder={`min: 0 max: 30000`}
                />
                <Input
                  title="Work experience"
                  value={form.workExperience}
                  type="float"
                  onInput={(value) => setForm({ ...form, workExperience: value })}
                  step={1}
                  min={0}
                  max={50}
                  placeholder={`min: 0  max: 50`}
                />
                {/* <Input
                  title="Presence of car"
                  value={form.presence_of_car}
                  type="integer"
                  onInput={(value) =>
                    setForm({ ...form, presence_of_car: value })
                  }
                  step={1}
                  min={0}
                  max={6 * 2}
                  placeholder={`min: 0  max: ${6 * 2}`}
                /> */}
                <Input
                  title="Dependants"
                  value={form.dependents}
                  type="integer"
                  onInput={(value) => setForm({ ...form, dependents: value })}
                  step={1}
                  min={0}
                  max={40}
                  placeholder={`min: 0  max: ${40}`}
                />
                {/* <Input
                  title="AECB"
                  value={form.aecb}
                  type="integer"
                  onInput={(value) => setForm({ ...form, aecb: value })}
                  step={1}
                  min={0}
                /> */}
              </div>
            </div>
            <div className={styles.footerBox}>
              <div className={styles.buttonGroup}>
                {formValid && (
                  <Button pending={pending} onClick={handleCalculate}>
                    Calculate
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className={styles.result}>
        {pending && (
          <div className={styles.resultBox}>
            <Loading size={50} />
          </div>
        )}
        {!!contextApplication.data?.scoring_log && <ResultBox />}
      </div>
    </div>
  );
};

export default Scoring;
