import { createModel } from "@rematch/core";
import global from "global";

const initialValue: global.user = {
  created_at: "2019-08-24T14:15:22Z",
  updated_at: "2019-08-24T14:15:22Z",
  id: "497f6eca-6276-4993-bfeb-53cbbbba6f08",
  email: "string",
  role: "expert",
  isActive: true,
};

const user = createModel()({
  state: initialValue as global.user,
  reducers: {
    SET(state, payload: Partial<global.user>) {
      return { ...state, ...payload };
    },
    REMOVE() {
      return initialValue;
    },
  },
});

export default user;
