import { AxiosError } from "axios";
import React from "react";

import Button from "components/button";
import RangeBox from "components/range-box";
import { toast } from "react-toastify";
import request from "request";
import styles from "./settings.module.scss";

const Settings = () => {
  const [updatePending, setUpdatePending] = React.useState(false);
  const [loadPending, setLoadPending] = React.useState(true);

  const [scoringThreshold, setScoringThreshold] = React.useState(0);
  // const [limitAECB, setLimitAECB] = React.useState(0);
  // const [dbrThreshold, setDbrThreshold] = React.useState(0);
  // const [ltvThreshold, setLtvThreshold] = React.useState(0);
  const [CPI, setCPI] = React.useState("");
  const [inflation_rate, setInflation_rate] = React.useState("");
  const [manufacturing_PMI, setManufacturing_PMI] = React.useState("");

  const handleSetSettings = () => {
    setUpdatePending(true);
    request
      .put("/admin/constant_values", {
        threshold: scoringThreshold / 100,
        // aecb_score_limit: limitAECB,
        // dbr_threshold: dbrThreshold,
        // ltv_threshold: ltvThreshold,
        CPI: Number(CPI),
        inflation_rate: Number(inflation_rate),
        manufacturing_PMI: Number(manufacturing_PMI),
      })
      .then(() => toast.success("Saved!"))
      .catch((err: AxiosError) => {
        toast.info((err.response?.data as any).detail);
      })
      .finally(() => setUpdatePending(false));
  };

  React.useEffect(() => {
    setLoadPending(true);
    request
      .get("/admin/constant_values")
      .then((res) => res.data)
      .then((data) => {
        setScoringThreshold(Math.round(data.threshold * 100));
        // setDbrThreshold(data.dbr_threshold);
        // setLtvThreshold(data.ltv_threshold);
        // setLimitAECB(data?.aecb_score_limit);
        setCPI(data.CPI);
        setInflation_rate(data.inflation_rate);
        setManufacturing_PMI(data.manufacturing_PMI);
      })
      .finally(() => setLoadPending(false));
  }, []);

  if (loadPending) return <></>;
  return (
    <form className={styles.settings}>
      <div className={styles.fields}>
        {/* <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>Macro variables</div>
          <div className={styles.fieldList}>
            <Input
              title="CPI"
              value={CPI}
              type="float"
              onInput={(value) => setCPI(value)}
              step={1}
            />
            <Input
              title="Manufacturing PMI"
              value={manufacturing_PMI}
              type="float"
              onInput={(value) => setManufacturing_PMI(value)}
              step={0.1}
            />
            <Input
              title="Inflation rate"
              value={inflation_rate}
              type="float"
              onInput={(value) => setInflation_rate(value)}
              step={0.1}
            />
          </div>
        </div> */}
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>Scoring Threshold (%)</div>
          <RangeBox value={scoringThreshold} setValue={setScoringThreshold} />
        </div>
        {/* <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>DBR Threshold (%)</div>
          <RangeBox value={dbrThreshold} setValue={setDbrThreshold} />
        </div>
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>LTV Threshold (%)</div>
          <RangeBox value={ltvThreshold} setValue={setLtvThreshold} />
        </div>
        <div className={styles.fieldGroup}>
          <div className={styles.fieldTitle}>AECB score limit</div>
          <RangeBox value={limitAECB} setValue={setLimitAECB} max={1000} />
        </div> */}
        <div className={styles.footerBox}>
          <Button pending={updatePending} onClick={handleSetSettings}>
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Settings;
