import global from "global";
import request from "request";
import { createModel } from "@rematch/core";

const initialValue: global.contextApplication = {
  pending: false,
  data: null,
};

const application = createModel()({
  state: initialValue as global.contextApplication,
  reducers: {
    START_PENDING(state) {
      return { ...state, pending: true };
    },
    STOP_PENDING(state) {
      return { ...state, pending: false };
    },
    SET(state, payload: global.application) {
      if (JSON.stringify(state.data) === JSON.stringify(payload)) return state;
      else return { ...state, data: { ...payload } };
    },
    REMOVE() {
      return initialValue;
    },
  },
  effects: (dispatch) => ({
    async FETCH_APPLICATION_BY_ID(id) {
      dispatch.application.START_PENDING();
      const response = await request.get("applications/data/" + id);
      const data = await response.data;
      dispatch.application.STOP_PENDING();
      dispatch.application.SET(data);
    },
  }),
});

export default application;
